import { mdiRead } from '@mdi/js';
import { LOGOPED } from './ids';
import { CatalogItem } from './types';

export default {
	id: LOGOPED,
	title: 'Логопед', // 1-2
	icon: mdiRead,
	description: 'Контроль и исправление нарушений произношения, письма и чтения',
	prices: [
		{
			title: 'Одноразовое занятие',
			price: 1500,
			count: 1,
		}, {
			title: 'Абонемент на 4 занятия',
			price: 3060,
			count: 4,
		}, {
			title: 'Абонемент на 8 занятий',
			price: 5780,
			count: 8,
		}, {
			title: 'Абонемент на 24 занятий',
			price: 16320,
			count: 24,
		},
	],
	route: 'logoped',
	schedule: {
		mon: '15:00 - 20:00',
		wed: '15:00 - 20:00',
		individual: true,
	},
} as CatalogItem;
