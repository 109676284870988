
import {
	defineComponent, onMounted, onUnmounted, ref, watch,
} from 'vue';

interface InputElement extends HTMLInputElement {
	validate?: () => boolean,
}

export default defineComponent({
	props: {
		modelValue: String,
		rule: Function,
		format: Function,
		required: Boolean,
	},
	emits: ['input', 'change', 'update:modelValue'],
	setup(props, ctx) {
		let wasBlurred = false;

		const value = ref(props.modelValue || '');
		const invalid = ref(false);
		const root = ref(null as InputElement|null);

		watch(() => props.modelValue, newValue => {
			value.value = newValue || '';
		});

		function validate(isBlurred: boolean): boolean {
			wasBlurred = wasBlurred || isBlurred || invalid.value;

			// Пока первый раз мы не покинем поле, считаем что идёт первая попытка ввода и подсвечивать ошибки не нужно
			if (!wasBlurred) {
				return true;
			}

			if (props.required && (value.value?.trim().length || 0) === 0) {
				return false;
			}

			return !props.rule || props.rule(value.value);
		}

		function doValidation(): boolean {
			const valid = validate(true);

			invalid.value = !valid;

			return valid;
		}

		function emit(): void {
			if (!invalid.value) {
				ctx.emit('update:modelValue', value.value);
			}
		}

		function onInput(): void {
			invalid.value = !validate(false);

			if (wasBlurred) {
				ctx.emit('input', value.value);
				emit();
			}
		}

		function onChange(): void {
			invalid.value = !validate(true);

			if (invalid.value) {
				return;
			}

			if (props.format) {
				value.value = props.format(value.value);
			}

			ctx.emit('update:modelValue', value.value);
			ctx.emit('change', value.value);
		}

		onMounted(() => {
			if (!root.value) {
				return;
			}

			root.value.validate = doValidation;
		});

		onUnmounted(() => {
			if (!root.value) {
				return;
			}

			delete root.value.validate;
		});

		return {
			value,
			invalid,
			onInput,
			onChange,
			root,
		};
	},
});
