
import { defineComponent } from 'vue';
import VNumber from './Number.vue';

export default defineComponent({
	setup() {
		const experience = (new Date()).getFullYear() - 2004;

		return {
			experience: `${experience}+`,
		};
	},
	components: {
		VNumber,
	},
});
