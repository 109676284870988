import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4ff1a0c2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "self-start" }
const _hoisted_2 = { class: "w-10 h-8 px-2 mt-2 text-center text-brand" }
const _hoisted_3 = ["textContent"]
const _hoisted_4 = {
  key: 0,
  class: "text-sm text-gray-500 font-light"
}
const _hoisted_5 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["service-row", { selected: _ctx.selected }])
  }, [
    (_ctx.value.route)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 0,
          class: "service-row-title",
          to: { name: _ctx.value.route }
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_v_icon, {
                  value: _ctx.value.icon
                }, null, 8, ["value"])
              ])
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("div", {
                class: "text-xl font-normal text-brand font-cuprum",
                textContent: _toDisplayString(_ctx.value.title)
              }, null, 8, _hoisted_3),
              (_ctx.minPrice)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, "от " + _toDisplayString(_ctx.minPrice) + " руб. за занятие", 1))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                class: "text-sm text-gray-600 font-normal",
                textContent: _toDisplayString(_ctx.value.description)
              }, null, 8, _hoisted_5)
            ])
          ]),
          _: 1
        }, 8, ["to"]))
      : _createCommentVNode("", true)
  ], 2))
}