<template>
<h2 class="title">Наша концепция</h2>
</template>

<style lang="scss" scoped>
.title {
	@apply flex justify-center items-center py-3;
	background: linear-gradient(
		0deg,
		rgba(238, 89, 135, 1) 0%,
		rgba(255, 183, 89, 1) 100%
	);
	border-radius: 20px;
	min-height: 72px;
	color: #ffffff;
	text-align: center;
	// font: 700 42px "Century Gothic", sans-serif;
}
</style>
