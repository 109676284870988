import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { ref: "form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_input = _resolveComponent("v-input")!
  const _component_v_icon = _resolveComponent("v-icon")!

  return (_openBlock(), _createElementBlock("form", _hoisted_1, [
    _createVNode(_component_v_input, {
      type: "text",
      modelValue: _ctx.name,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
      placeholder: "Ваше имя"
    }, null, 8, ["modelValue"]),
    _createVNode(_component_v_input, {
      type: "text",
      inputmode: "tel",
      modelValue: _ctx.phone,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.phone) = $event)),
      placeholder: "Контактный номер телефона",
      required: "",
      rule: _ctx.phoneValidator,
      format: _ctx.formatPhone
    }, null, 8, ["modelValue", "rule", "format"]),
    _createElementVNode("button", {
      class: "mt-6 submit",
      onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["prevent"]))
    }, [
      _createVNode(_component_v_icon, {
        value: _ctx.icons.sign
      }, null, 8, ["value"]),
      _createTextVNode("Записаться")
    ])
  ], 512))
}