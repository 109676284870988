/* eslint-disable no-param-reassign */
const defaultLocalCode = '8';
const defaultInternationalCode = '7';

const replacePattern = /[^+\d]+/g;

interface Params {
	readonly limit?: number,
	readonly localCode?: string | number,
	readonly internationalCode?: string | number,
}

function splitLocalPart(phone: string): [string, string] {
	const localPart: string[] = [];

	localPart.push(phone.slice(-2));
	phone = phone.slice(0, -2);

	localPart.push(phone.slice(-2));
	phone = phone.slice(0, -2);

	localPart.push(phone.slice(-3));
	phone = phone.slice(0, -3);

	return [
		phone,
		localPart.reverse().filter(part => part.length > 0).join('-'),
	];
}

function splitCityCode(globalPart: string, params: Params): [string, string] {
	let countryCode = globalPart.slice(0, -3);
	const cityCode = globalPart.slice(-3);

	if (cityCode.length === 3) {
		if (!countryCode || countryCode === params.localCode) {
			countryCode = `+${params.internationalCode}`;
		} else if (countryCode.slice(0, 1) !== '+') {
			countryCode = `+${countryCode}`;
		}
	}

	return [countryCode, cityCode];
}

export default function formatPhone(value: string, params?: Params): string {
	const definedParams: Params = {
		...params,
		localCode: params?.localCode || defaultLocalCode,
		internationalCode: params?.internationalCode || defaultInternationalCode,
	};

	return value
		.replace(';', ',') // Телефоны можно разделять запятыми и точками с запятой
		.split(',') // создаём массив телефонов
		.map(phone => {
			phone = phone.replace(replacePattern, ''); // удаляем все символы, кроме '+' и цифр

			phone = phone.slice(0, 1) + phone.slice(1).replace('+', ''); // избавляемся от плюсов везде, кроме начала строки

			const [globalPart, localPart] = splitLocalPart(phone);

			if (localPart.length < 7) { // 1-23-45
				return '';
			}

			const [countryCode, cityCode] = splitCityCode(globalPart, definedParams);

			return cityCode ? `${countryCode} (${cityCode}) ${localPart}` : localPart;
		})
		.join(', ');
}
