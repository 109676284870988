import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5b29fedc"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]
const _hoisted_2 = {
  key: 0,
  class: "ads-controls"
}
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "ads",
      style: _normalizeStyle(_ctx.slide2Style)
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ads, (ad) => {
        return (_openBlock(), _createElementBlock(_Fragment, null, [
          (ad.external)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                class: _normalizeClass(["ad", ad.cls]),
                href: ad.to,
                target: "_blank",
                style: _normalizeStyle({ backgroundImage: ad.image, backgroundColor: ad.bg })
              }, null, 14, _hoisted_1))
            : (ad.to)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 1,
                  class: _normalizeClass(["ad", ad.cls]),
                  to: ad.to,
                  style: _normalizeStyle({ backgroundImage: ad.image, backgroundColor: ad.bg })
                }, null, 8, ["class", "to", "style"]))
              : (_openBlock(), _createElementBlock("div", {
                  key: 2,
                  class: _normalizeClass(["ad", ad.cls]),
                  style: _normalizeStyle({ backgroundImage: ad.image, backgroundColor: ad.bg })
                }, null, 6))
        ], 64))
      }), 256))
    ], 4),
    (_ctx.ads.length > 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ads, (ad, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(["ad-button", { selected: index === _ctx.currentAdIndex }]),
              onClick: ($event: any) => (_ctx.setCurrentAd(index))
            }, null, 10, _hoisted_3))
          }), 256))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}