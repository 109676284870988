import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cart" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cart_window = _resolveComponent("cart-window")!
  const _component_sent_message = _resolveComponent("sent-message")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_cart_window, {
      onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
      onSent: _ctx.sent,
      modelValue: _ctx.isCartExpanded,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isCartExpanded) = $event))
    }, null, 8, ["onSent", "modelValue"]),
    _createVNode(_component_sent_message, {
      modelValue: _ctx.wasSent,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.wasSent) = $event))
    }, null, 8, ["modelValue"])
  ]))
}