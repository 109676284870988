import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_footer = _resolveComponent("v-footer")!
  const _component_cart = _resolveComponent("cart")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["root", _ctx.classes]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.mainMenuActive = false))
    }, [
      _createVNode(_component_router_view),
      _createVNode(_component_v_footer)
    ], 2),
    _createVNode(_component_cart)
  ], 64))
}