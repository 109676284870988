
import {
	defineComponent, ref, watch,
} from 'vue';
import VWindow from '../Window.vue';

export default defineComponent({
	emits: ['update:modelValue'],
	props: {
		modelValue: Boolean,
	},

	setup(props, ctx) {
		const isShown = ref(!!props.modelValue);

		function close(): void {
			isShown.value = false;
		}

		watch(props, () => {
			isShown.value = !!props.modelValue;
		});

		watch(isShown, () => {
			ctx.emit('update:modelValue', isShown.value);
		});

		return {
			close,
			isShown,
		};
	},
	components: {
		VWindow,
	},
});
