
import { defineComponent } from 'vue';

const buttonClasses = Array.from(Array(7)).map((_, i) => `b${i}`);

export default defineComponent({
	props: {
		modelValue: {
			type: Number,
			required: true,
		},
	},
	emits: ['update:modelValue'],
	setup(props, ctx) {
		function buttonClick(index: number) {
			if (props.modelValue === index) {
				return;
			}

			ctx.emit('update:modelValue', index);
		}

		return {
			buttonClick,
			buttonClasses,
		};
	},
});
