
import { defineComponent, ref, watch } from 'vue';
import axios from 'axios';
import { mdiCheck } from '@mdi/js';

import formatPhone from '@/format/phone';
import { reachGoal } from '@/etc';
import VInput from './Input.vue';
import { cart } from './cart';

const phoneMatch = /^((\+?7|8?)\d{10}|(342)?2\d{6})/;

const icons = {
	sign: mdiCheck,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function nameValidator(_value: string): boolean {
	return true;
}

function phoneValidator(value: string): boolean {
	const numbers = value.replace(/[^+\d]+/ig, '');
	return phoneMatch.test(numbers);
}

export default defineComponent({
	emits: ['sent'],
	setup(_props, ctx) {
		const name = ref(localStorage.getItem('name') || '');
		const phone = ref(localStorage.getItem('phone') || '');
		const form = ref(null as HTMLFormElement|null);
		let loading = false;

		watch([name, phone], ([name, phone]) => {
			localStorage.setItem('name', name);
			localStorage.setItem('phone', phone);
		});

		async function submit(): Promise<void> {
			if (loading || !form.value) {
				console.debug(loading, !!form.value);
				return;
			}

			reachGoal('form-try');

			const handle = form.value;

			let isValid = true;

			for (let i = 0; i < handle.elements.length; i++) {
				const el = handle.elements[i] as unknown as { validate?: () => boolean };

				isValid = isValid && (!el.validate || el.validate());
			}

			if (!isValid) {
				console.debug('Form is invalid');
				return;
			}

			try {
				loading = true;
				const data = new FormData();
				data.set('name', name.value);
				data.set('phone', phone.value);
				data.set('items', cart.items.map(item => item.id).join(';'));

				const response = await axios.post('/send.php', data);

				if (response.status !== 200) {
					throw new Error(response.statusText);
				}

				reachGoal('form-sent');
				ctx.emit('sent');
			} catch (e) {
				// eslint-disable-next-line no-alert
				alert('Не удалось отправить сообщение. Проверьте подключение к сети "Интернет".');
			} finally {
				loading = false;
			}
		}

		return {
			nameValidator,
			phoneValidator,
			submit,
			name,
			phone,
			formatPhone,
			icons,
			form,
		};
	},
	components: {
		VInput,
	},
});
