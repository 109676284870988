import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, Transition as _Transition, withCtx as _withCtx, KeepAlive as _KeepAlive, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-08604a64"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "window-inner" }
const _hoisted_3 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!

  return (_openBlock(), _createBlock(_KeepAlive, null, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.isShown)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "window-bg",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
            }, [
              (_ctx.raw)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
                  ]))
                : (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: "window-outer",
                    onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
                  }, [
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("button", {
                        class: "exit",
                        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
                      }, [
                        _createVNode(_component_v_icon, {
                          value: _ctx.icons.close
                        }, null, 8, ["value"])
                      ]),
                      (_ctx.title)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: "title",
                            textContent: _toDisplayString(_ctx.title)
                          }, null, 8, _hoisted_3))
                        : _createCommentVNode("", true),
                      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
                    ])
                  ]))
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ], 1024))
}