
import { defineComponent } from 'vue';

export default defineComponent({
	props: {
		value: String,
	},
	setup(props) {
		const style = {
			backgroundImage: `url(${props.value})`,
			// width: '50px',
		};
		console.log(style);

		return {
			style,
		};
	},
});
