import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "max-w-screen-xl mx-auto" }
const _hoisted_2 = { class: "flex flex-col md:flex-row align-middle" }
const _hoisted_3 = { class: "w-full md:w-2/3" }
const _hoisted_4 = { class: "flex w-full md:w-1/3 flex-col justify-center px-4 py-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_conception_title = _resolveComponent("conception-title")!
  const _component_main_circle = _resolveComponent("main-circle")!
  const _component_step_description = _resolveComponent("step-description")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_conception_title, { class: "mx-4" }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_main_circle, {
          modelValue: _ctx.stepIndex,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.stepIndex) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_step_description, {
          step: _ctx.stepIndex,
          class: "flex-shrink"
        }, null, 8, ["step"])
      ])
    ])
  ]))
}