export const enum Direction {
	Left,
	Right,
}

const DEAD_ZONE = 60;

interface Exported {
	touchstart: (event: TouchEvent) => void,
	touchmove: (event: TouchEvent) => void,
	touchend: (event: TouchEvent) => void,

}
export default function (callback: (direction: Direction) => void): Exported {
	let started = false;
	let happend = false;
	let pivotX: number | null = null;

	function touchstart(event: TouchEvent): void {
		if (event.touches.length !== 1 || started || happend) {
			return;
		}

		started = true;

		pivotX = event.changedTouches[0].pageX;
	}

	function touchmove(event: TouchEvent): void {
		if (pivotX === null || happend) {
			return;
		}

		const x = event.changedTouches[0].pageX;

		if (Math.abs(x - pivotX) > DEAD_ZONE) {
			happend = true;

			if (x - pivotX > 0) {
				callback(Direction.Left);
			} else {
				callback(Direction.Right);
			}
		}
	}

	function touchend(): void {
		started = false;
		happend = false;
		pivotX = null;
	}

	return {
		touchstart,
		touchmove,
		touchend,
	};
}
