
import { defineComponent, ref } from 'vue';

import age712 from '@/catalog/age7-12';
import { ItemId } from '@/catalog/types';
import AgeTab from './AgeTab.vue';
import ServiceRow from './ServiceRow.vue';

const TAB_ID = 'tab4';

export default defineComponent({
	setup() {
		const selectedId = ref(+(sessionStorage.getItem(TAB_ID) || 0));

		function click(id: ItemId) {
			selectedId.value = id;

			sessionStorage.setItem(TAB_ID, `${id}`);
		}

		return {
			items: age712,
			click,
			selectedId,
		};
	},
	components: {
		AgeTab,
		ServiceRow,
	},
});
