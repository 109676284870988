import { createApp } from 'vue';
import VIcon from '@/components/Icon.vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import './style.scss';

createApp(App)
	.component('VIcon', VIcon)
	.use(router)
	.mount('#app')
;
