function padding(part: number, index: number): string {
	if (index > 0 && part < 100) {
		let pad = '';
		const padCount = 3 - String(part).length;

		for (let i = 0; i < padCount; i++) {
			pad = `${pad}0`;
		}

		return `${pad}${part}`;
	}

	return String(part);
}
export default function (price:number, isD = false, _ = false):string { // eslint-disable-line @typescript-eslint/no-unused-vars, no-unused-vars
	const value = Number(price) || 0;

	// Нужно обязательно делать Math.abs,
	// иначе округление отрицательных чисел может вызывать некорректный результат работы ф-ции
	const isNegative = value < -0.005;
	const rounded = Math.round(Math.abs(value) * 100);
	const wholePart = Math.floor(rounded / 100);
	const subPart = rounded % 100;

	const parts:number[] = [];
	let tmp = wholePart;

	// Делим на части по 1000
	do {
		parts.push(tmp % 1000);
		tmp = Math.floor(tmp / 1000);
	} while (tmp >= 1);

	// В частях (кроме первой) дописываем нули для значений, менее 100
	const stringParts = parts.reverse().map(padding);

	const result = (isNegative ? '-' : '') + stringParts.join(' ');

	const subPartStr = subPart > 10 ? String(subPart) : `0${String(subPart)}`;

	return isD ? `${result}-${subPartStr}` : result;
}
