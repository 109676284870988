import age12 from './catalog/age1-2';
import age35 from './catalog/age3-5';
import age67 from './catalog/age6-7';
import age712 from './catalog/age7-12';
import forFun from './catalog/for-fun';
import others from './catalog/others';
import { CatalogItem, ItemId } from './catalog/types';

const catalog = [
	...age12,
	...age35,
	...age67,
	...age712,
	...forFun,
	...others,
];

const map: Map<ItemId, CatalogItem> = new Map();

catalog.forEach(item => map.set(item.id, item));

export function getCatalogItem(itemId: ItemId): CatalogItem | undefined {
	return map.get(itemId);
}

export default [...map.values()];
