import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5b09cd82"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["textContent"]
const _hoisted_2 = { class: "description" }
const _hoisted_3 = {
  key: 0,
  class: "btn"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    class: "service-item",
    to: _ctx.url
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: "item-image",
        style: _normalizeStyle({ backgroundImage: _ctx.backgroundImage })
      }, null, 4),
      _createElementVNode("h3", {
        class: "text-center",
        textContent: _toDisplayString(_ctx.title)
      }, null, 8, _hoisted_1),
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true),
        (_ctx.route)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, "Подробнее"))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 3
  }, 8, ["to"]))
}