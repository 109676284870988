import { mdiTranslate, mdiAccountChild } from '@mdi/js';
import { PRODLENKA } from './ids';
import logoped from './logoped';
import shkolaZdoroviya from './shkola-zdoroviya1';
import { CatalogItem } from './types';

export default [
	/* {
		id: 12,
		title: 'Для школьников',
		age: '6-7',
		prices: [
			{
				title: 'Индивидуальное занятие (30 мин) / Разовое посещение',
				price: 825,
				count: 1,
			}, {
				title: 'Индивидуальное занятие (30 мин) / Абонемент на 4 занятия',
				price: 2970,
				count: 4,
			}, {
				title: 'Индивидуальное занятие (30 мин) / Абонемент на 12 занятий',
				price: 5610,
				count: 12,
			}, {
				title: 'Индивидуальное занятие (45 мин) / Разовое посещение',
				price: 935,
				count: 1,
			}, {
				title: 'Индивидуальное занятие (45 мин) / Абонемент на 4 занятия',
				price: 3366,
				count: 4,
			}, {
				title: 'Индивидуальное занятие (45 мин) / Абонемент на 12 занятий',
				price: 6358,
				count: 12,
			},
		],
		icon: mdiHumanMaleChild,
		route: 'podgotovka-k-shkole',
	}, */ {
		id: 13,
		title: 'Английский язык',
		age: '7-12',
		icon: mdiTranslate,
		description: 'Языковая поддержка для совершенствования знаний английского языка: грамматики и лексики',
		prices: [
			{
				title: 'Групповое занятие (45 мин) / Разовое посещение',
				price: 660,
				count: 1,
			}, {
				title: 'Групповое занятие (45 мин) / Абонемент на 4 занятия',
				price: 2615,
				count: 4,
			}, {
				title: 'Групповое занятие (45 мин) / Абонемент на 8 занятий',
				price: 4935,
				count: 8,
			}, {
				title: 'Групповое занятие (45 мин) / Абонемент на 24 занятия',
				price: 13935,
				count: 24,
			}, {
				title: 'Индивидуальное занятие (45 мин) / Разовое посещение',
				price: 1089,
				count: 1,
			}, {
				title: 'Индивидуальное занятие (45 мин) / Абонемент на 4 занятия',
				price: 3920,
				count: 4,
			}, {
				title: 'Индивидуальное занятие (45 мин) / Абонемент на 12 занятий',
				price: 7405,
				count: 8,
			}, {
				title: 'Индивидуальное занятие (45 мин) / Абонемент на 24 занятия',
				price: 20908,
				count: 24,
			},
		],
		route: 'english-group-4',
	},
	logoped,
	shkolaZdoroviya,
	// {
	//     id: 19,
	//     title: 'Музыкально-театральная студия',
	//     age: '7-12',
	//     prices: [
	//         {
	//             title: 'Разовое занятие',
	//             price: 750,
	//             count: 1,
	//         }, {
	//             title: 'Абонемент на 4 занятия',
	//             price: 2700,
	//             count: 4,
	//         }, {
	//             title: 'Разовое занятие в детском саду',
	//             price: 525,
	//             count: 1,
	//         }, {
	//             title: 'Абонемент на 4 занятия в детском саду',
	//             price: 2100,
	//             count: 4,
	//         },
	//     ],
	//     icon: mdiMusicClefTreble,
	//     route: 'music-studio',
	// },
	{
		id: PRODLENKA,
		title: 'Продлёнка',
		icon: mdiAccountChild,
		description: '',
		prices: [
			{
				title: '12:00 - 17:00, день, с клубной картой',
				price: 625,
				count: 1,
			},
			{
				title: '12:00 - 18:00, день, с клубной картой',
				price: 675,
				count: 1,
			},
			{
				title: '12:00 - 17:00, день, без клубной карты',
				price: 1100,
				count: 1,
			},
			{
				title: '12:00 - 18:00, день, без клубной карты',
				price: 1200,
				count: 1,
			},
			{
				title: '12:00 - 17:00, абонемент (месяц), с клубной картой',
				price: 12500,
			},
			{
				title: '12:00 - 18:00, абонемент (месяц), с клубной картой',
				price: 13500,
			},
		],
		route: 'prodlenka',
		schedule: {
			sun: '12:00 - 18:00',
			mon: '12:00 - 18:00',
			tue: '12:00 - 18:00',
			wed: '12:00 - 18:00',
			thu: '12:00 - 18:00',
			fri: '12:00 - 18:00',
			sat: '12:00 - 18:00',
		},
	},
] as CatalogItem[];
