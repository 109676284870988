import { reactive, ref } from 'vue';
import config from '@/config';
import { CatalogItem } from '@/catalog/types';
import { reachGoal } from '@/etc';

// При импорте ItemId из types.d почему-то слетает крыша у компилятора
type ItemId = number;

const STORAGE_KEY = 'cart';
const CART_TIME_LIMIT = 24 * 3600 * 7 * 1000;

type CartItem = CatalogItem;

interface Cart {
	updatedAt: number,
	items: CartItem[],
}

interface SerializedCart {
	updatedAt: number,
	items: ItemId[],
}

const cart: Cart = reactive({
	items: [],
	updatedAt: 0,
});

function saveCart(): void {
	const { updatedAt, items: itemRefs } = cart;

	const items = itemRefs.map(item => item.id);

	const sCart: SerializedCart = { updatedAt, items };

	const s = JSON.stringify(sCart);

	if (s) {
		localStorage.setItem(STORAGE_KEY, s);
	}
}

function getItemById(id: ItemId): CartItem | undefined {
	return config.catalog.find(item => item.id === id);
}

function loadCart(): void {
	const serialized = localStorage.getItem(STORAGE_KEY);
	const loadedCart: SerializedCart | null | undefined = serialized && JSON.parse(serialized);

	if (!loadedCart) {
		return;
	}

	const now = (new Date()).valueOf();

	// Если корзина давно не обновлялась, то не загружаем данные
	if (now - loadedCart.updatedAt > CART_TIME_LIMIT) {
		return;
	}

	const items = loadedCart.items.map(getItemById)
		.filter(item => !!item) as CatalogItem[];

	cart.items.push(...items);
	cart.updatedAt = loadedCart.updatedAt;
}

function isInCart(id: ItemId): boolean {
	return cart.items.some(item => item.id === id);
}

function addToCart(itemId: ItemId | string): void {
	const id = +itemId;

	if (isInCart(id)) {
		return;
	}

	reachGoal('add-to-cart');

	const item = getItemById(id);

	if (item) {
		cart.items.push(item);
		cart.updatedAt = (new Date()).valueOf();
	}

	saveCart();
}

function removeFromCart(itemId: ItemId | string): void {
	const id = +itemId;

	const index = cart.items.findIndex(item => item.id === id);

	if (index >= 0) {
		cart.items.splice(index, 1);
		saveCart();
	}
}

loadCart();

const isCartExpanded = ref(false);

function showCart(): void {
	isCartExpanded.value = true;
}

function switchCart(): void {
	isCartExpanded.value = !isCartExpanded.value;
}

function closeCart(): void {
	isCartExpanded.value = false;
}

export {
	cart,
	isCartExpanded,
	ItemId,
	addToCart,
	removeFromCart,
	isInCart,
	showCart,
	switchCart,
	closeCart,
};
