
import { mdiClose } from '@mdi/js';
import { defineComponent, watch, ref } from 'vue';

const icons = {
	close: mdiClose,
};

export default defineComponent({
	emits: ['open', 'close', 'update:modelValue'],

	props: {
		title: String,
		modelValue: Boolean,
		raw: Boolean,
	},

	setup(props, ctx) {
		const isShown = ref(!!props.modelValue);

		function close(): void {
			isShown.value = false;
			ctx.emit('update:modelValue', false);
		}

		watch(props, () => {
			isShown.value = !!props.modelValue;
		});

		watch(isShown, () => {
			ctx.emit(isShown.value ? 'open' : 'close');
		});

		return {
			close,
			isShown,
			icons,
		};
	},
});
