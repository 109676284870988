
import { defineComponent } from 'vue';

export default defineComponent({
	props: {
		withTitle: Boolean,
		withBg: Boolean,
		titlePosition: String, // 'bottom' | 'right'
	},
	computed: {
		src(): string {
			let logoType = this.withBg ? 'b' : 'a';
			const title = this.withTitle ? 't' : '';

			if (this.titlePosition === 'right' && title) {
				logoType = 'c';
			}

			return `/img/logo-${logoType}${title}.png`;
		},
	},
});
