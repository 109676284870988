import { vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("input", {
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
    onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
    onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args))),
    class: _normalizeClass({ invalid: _ctx.invalid }),
    ref: "root"
  }, null, 34)), [
    [_vModelText, _ctx.value]
  ])
}