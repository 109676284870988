import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("button", {
      class: "menu-activator colorful",
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.switchMenu && _ctx.switchMenu(...args)), ["stop"]))
    }, [
      _createVNode(_component_v_icon, {
        value: _ctx.icons.menu
      }, null, 8, ["value"])
    ]),
    _createElementVNode("ul", {
      class: _normalizeClass(["main-menu colorful bg-fixed sticky top-0", { active: _ctx.active }]),
      onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu, (item) => {
        return (_openBlock(), _createElementBlock("li", null, [
          (item.mobileOnly)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                class: "mobile-only",
                to: item,
                textContent: _toDisplayString(item.title)
              }, null, 8, ["to", "textContent"]))
            : (_openBlock(), _createBlock(_component_router_link, {
                key: 1,
                to: item,
                textContent: _toDisplayString(item.title)
              }, null, 8, ["to", "textContent"]))
        ]))
      }), 256))
    ], 2)
  ], 64))
}