import catalog from '@/catalog';

export default {
	address: ['г. Пермь, ул. Кронштадтская, д. 4', 'г. Пермь, ул. Кронштадтская, д. 47'],
	schedule: 'ПН-ПТ: с 7:00 до 20:00; СБ-ВС: с 9:00 до 18:00',
	phones: [
		{
			text: '(342) 288-75-79',
			callable: '+73422887579',
		}, {
			text: '+7 (992) 213-14-51',
			callable: '+79922131451',
		},
	],
	emails: ['7perm@mail.ru'],
	vk: 'https://vk.com/semitsvetieperm',
	insta: 'https://www.instagram.com/semitsvetieperm/',
	youtube: 'https://www.youtube.com/@semitsvetieperm',
	telegram: 'https://t.me/semitsvetie_perm',
	dzen: 'https://dzen.ru/semisvetieperm',
	catalog,
	ym: [7593760],
};
