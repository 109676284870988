
import { defineComponent, ref } from 'vue';
import MainCircle from './MainCircle.vue';
import ConceptionTitle from './Title.vue';
import StepDescription from './StepDescription.vue';

export default defineComponent({
	setup() {
		// PrerenderSPA plugin has some kind of weird bug. It stucks on prerendering main page if
		// we're selecting element on initialization
		// The only way I found is to hide selected element on prerender stage and show it on other
		// runtime
		const isPrerendering = '__PRERENDER_INJECTED' in window;
		const stepIndex = ref(isPrerendering ? -1 : 0);

		return { stepIndex };
	},
	components: {
		MainCircle,
		ConceptionTitle,
		StepDescription,
	},
});
