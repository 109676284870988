import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-60067292"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-col shadow-lg bg-white rounded-xl overflow-hidden h-full" }
const _hoisted_2 = { class: "marker" }
const _hoisted_3 = ["textContent"]
const _hoisted_4 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "logo",
      style: _normalizeStyle({ backgroundImage: _ctx.backgroundImage })
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: "text-4xl font-oswald",
          textContent: _toDisplayString(_ctx.age)
        }, null, 8, _hoisted_3),
        _createElementVNode("div", {
          class: "text-sm font-oswald -mt-1",
          textContent: _toDisplayString(_ctx.ageWord)
        }, null, 8, _hoisted_4)
      ])
    ], 4),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ]))
}