
import { defineComponent, ref } from 'vue';

import age12 from '@/catalog/age1-2';
import { ItemId } from '@/catalog/types';
import AgeTab from './AgeTab.vue';
import ServiceRow from './ServiceRow.vue';

const TAB_ID = 'tab1';

export default defineComponent({
	setup() {
		const selectedId = ref(+(sessionStorage.getItem(TAB_ID) || 0));

		function click(id: ItemId) {
			selectedId.value = id;

			sessionStorage.setItem(TAB_ID, `${id}`);
		}

		return {
			items: age12,
			click,
			selectedId,
		};
	},
	components: {
		AgeTab,
		ServiceRow,
	},
});
