
import { defineComponent } from 'vue';

export default defineComponent({
	props: {
		age: String,
		image: {
			type: String,
			required: true,
		},
	},
	computed: {
		backgroundImage(): string {
			return `url(${this.image})`;
		},
		ageWord(): string {
			const age = this.age || '';
			const maxAge = age
				.split('-')
				.map(v => +v)
				.filter(Number.isFinite)
				.filter(v => v > 0)
				.reduce((max, v) => Math.max(max, v))
			;

			switch (maxAge) {
				case 0:
					return '';
				case 1:
					return 'год';
				case 2:
				case 3:
				case 4:
					return 'года';
				default:
					return 'лет';
			}
		},
	},
});
