import { mdiSoccer } from '@mdi/js';
import { SHKOLA_ZDOROVYA } from './ids';
import { CatalogItem } from './types';

export default {
	id: SHKOLA_ZDOROVYA,
	title: 'Школа здоровья',
	icon: mdiSoccer,
	description: 'Нравственное и физическое развитие детей',
	prices: [
		{
			title: 'Разовое занятие',
			price: 800,
			count: 1,
		}, {
			title: 'Абонемент на 4 занятия',
			price: 2880,
			count: 4,
		}, {
			title: 'Абонемент на 8 занятий',
			price: 5440,
			count: 8,
		}, {
			title: 'Абонемент на 24 занятия',
			price: 15360,
			count: 24,
		},
	],
	route: 'shkola-zdorovia-group-1',
	schedule: {
		mon: '19:00 - 20:00',
		fri: '19:00 - 20:00',
	},
} as CatalogItem;
