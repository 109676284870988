import {
	createRouter, createWebHistory,
} from 'vue-router';
import Home from '@/views/Home.vue';
import scrollBehavior from './scrollBehavior';
import getRoutes from './routes';

export const routes = getRoutes(Home);

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	scrollBehavior,
	routes,
});

router.afterEach((to/* , from, failure */) => {
	const title = to.meta.title as string|undefined;

	if (title) {
		document.title = `${title} | Семицветие`;
	} else {
		document.title = 'Детский центр "Семицветие"';
	}
});

export default router;
