
import { defineComponent, ref, computed } from 'vue';
import { RouteLocationRaw } from 'vue-router';

type Ad = {
	cls?: string;
	to?: RouteLocationRaw;
	external?: boolean;
	image: string;
	bg?: string;
};

const ads: ReadonlyArray<Ad> = [
	{
		to: '/programs/detskiy-sad/yasli',
		image: 'url(/img/banners/yasli.png)',
	},
	{
		image: 'url(/img/banners/ad1.png)',
	},
	{
		image: 'url(/img/banners/discount.png)',
	},
	{
		image: 'url(/img/banners/ad2.png)',
	},
	{
		to: '/fun/vecherinki',
		image: 'url(/img/banners/vecherinki.jpg)',
	},
];

const rotateDuration = 3500;
let rotateInterval = undefined as number | undefined;

export default defineComponent({
	mounted() {
		this.resetInterval();
	},
	beforeUnmount() {
		clearInterval(rotateInterval);
	},
	setup() {
		const currentAdIndex = ref(0);

		const slide2Style = computed(() => {
			const size = currentAdIndex.value * 100;
			return {
				marginLeft: `-${size}%`,
			};
		});

		function resetInterval(timeout?: number) {
			clearInterval(rotateInterval);

			if (ads.length <= 1) {
				return;
			}

			rotateInterval = setInterval(() => {
				currentAdIndex.value++;
				currentAdIndex.value %= ads.length;
			}, timeout || rotateDuration);
		}

		function setCurrentAd(index: number) {
			currentAdIndex.value = index;
			resetInterval(rotateDuration * 2);
		}

		return {
			ads,
			currentAdIndex,
			slide2Style,
			setCurrentAd,
			resetInterval,
		};
	},
});
