
import { mdiCartVariant } from '@mdi/js';
import {
	computed, defineComponent, watch, ref,
} from 'vue';
import { cart } from './cart';

const icons = {
	cart: mdiCartVariant,
};

export default defineComponent({
	setup() {
		const items = computed(() => cart.items);
		const itemsCount = computed(() => items.value.length);
		const showCart = computed(() => itemsCount.value > 0);
		const word = computed(() => {
			const count = itemsCount.value;
			if (count > 10 && count < 20) {
				return 'услуг';
			}

			switch (count % 10) {
				case 1:
					return 'услуга';
				case 2:
				case 3:
				case 4:
					return 'услуги';

				default:
					return 'услуг';
			}
		});

		const animated = ref(false);
		let animationTimeout: undefined | number;

		watch(itemsCount, (value, oldValue) => {
			// Включаем анимашку, только если была добавлена услуга
			if (value === 1 || oldValue - value > 0) {
				return;
			}

			animated.value = true;
			clearTimeout(animationTimeout);
			animationTimeout = setTimeout(() => { animated.value = false; }, 150);
		});

		return {
			cart,
			items,
			showCart,
			icons,
			itemsCount,
			word,
			animated,
		};
	},
});
