import { mdiSchool } from '@mdi/js';
import { VOSKRESNAYA_SHKOLA } from './ids';
import { CatalogItem } from './types';

export default {
	id: VOSKRESNAYA_SHKOLA,
	title: 'Подготовка к школе',
	description: 'Чтобы малыш не испытывал трудностей при обучении',
	icon: mdiSchool,
	prices: [
		{
			title: 'Группа 3-4 / Разовое посещение',
			price: 935,
			count: 1,
		}, {
			title: 'Группа 3-4 / Абонемент на 4 занятия',
			price: 3354,
			count: 4,
		}, {
			title: 'Группа 3-4 / Абонемент на 8 занятий',
			price: 6336,
			count: 8,
		}, {
			title: 'Группа 3-4 / Абонемент на 24 занятия',
			price: 16262,
			count: 24,
		}, {
			title: 'Группа 4-5 / Разовое посещение',
			price: 1150,
			count: 1,
		}, {
			title: 'Группа 4-5 / Абонемент на 4 занятия',
			price: 4140,
			count: 4,
		}, {
			title: 'Группа 4-5 / Абонемент на 8 занятий',
			price: 7815,
			count: 8,
		}, {
			title: 'Группа 4-5 / Абонемент на 24 занятия',
			price: 20064,
			count: 24,
		}, {
			title: 'Репититорство / индивидуальное занятие (30 мин) / Разовое посещение',
			price: 908,
			count: 1,
		}, {
			title: 'Репититорство / индивидуальное занятие (30 мин) / Абонемент на 4 занятия',
			price: 3267,
			count: 4,
		}, {
			title: 'Репититорство / индивидуальное занятие (30 мин) / Абонемент на 12 занятий',
			price: 6171,
			count: 8,
		}, {
			title: 'Репититорство / индивидуальное занятие (45 мин) / Разовое посещение',
			price: 1028,
			count: 1,
		}, {
			title: 'Репититорство / индивидуальное занятие (45 мин) / Абонемент на 4 занятия',
			price: 3705,
			count: 4,
		}, {
			title: 'Репититорство / индивидуальное занятие (45 мин) / Абонемент на 12 занятий',
			price: 6995,
			count: 8,
		},
	],
	route: 'podgotovka-k-shkole',
	schedule: {
		mon: '8:00 до 12:00',
		tue: '8:00 до 12:00',
		wed: '8:00 до 12:00',
		thu: '8:00 до 12:00',
		fri: '8:00 до 12:00',
		sun: ' ',
	},
} as CatalogItem;
