
import { defineComponent } from 'vue';
import { mdiYoutube } from '@mdi/js';
import config from '@/config';

const icons = {
	youtube: mdiYoutube,
};

const videos = [
	'https://www.youtube.com/embed/nI7JmCy6EuY',
	'https://www.youtube.com/embed/6Zd6_H_1hrc',
	'https://www.youtube.com/embed/R7nVwyIsWR8',
	'https://www.youtube.com/embed/n9ZhZXcOva4',
	'https://www.youtube.com/embed/_Lk14H043lQ',
	'https://www.youtube.com/embed/QcmhR7CLMcg',
];

export default defineComponent({
	setup() {
		return {
			icons,
			config,
			videos: videos.slice(-4),
		};
	},
});
