export type ItemId = number;

export type Age = '1-2' | '3-5' | '6-7' | '7-12';

export interface Price {
	title: string,
	price: number,
	count?: number,
	monthly?: boolean,
	condition?: ItemId[],
}

export enum PricePunctuation {
	Separator,
}

export type PriceItem = Price | PricePunctuation;

export function isPrice(priceItem: PriceItem): priceItem is Price {
	return priceItem !== PricePunctuation.Separator;
}

export interface CatalogItem {
	id: ItemId,
	title: string,
	description: string,
	age?: Age,
	icon: string,
	route: string,
	prices: PriceItem[],
	pricesDescription?: string,
	schedule?: {
		mon?: string,
		tue?: string,
		wed?: string,
		thu?: string,
		fri?: string,
		sat?: string,
		sun?: string,
		individual?: boolean,
	},
}
