export const YASLI = 1;
export const ENGLISH_1_2 = 2;
export const SHRR = 4;
export const DETSKIY_SAD = 5;
export const ENGLISH_3_5 = 6;
export const LOGOPED = 7;
export const VOSKRESNAYA_SHKOLA = 8;
export const SHKOLA_ZDOROVYA = 9;
export const INTELLEKTIKA = 18;
export const PRODLENKA = 21;
