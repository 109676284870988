import { mdiHeadSnowflakeOutline, mdiMicrophone } from '@mdi/js';
import { INTELLEKTIKA } from './ids';
import { CatalogItem } from './types';

export default [
	{
		id: INTELLEKTIKA,
		title: 'Интеллектика',
		icon: mdiHeadSnowflakeOutline,
		description: '',
		prices: [
			{
				title: 'Одно занятие',
				price: 863,
				count: 1,
			}, {
				title: 'Абонемент на 4 занятия',
				price: 3115,
				count: 4,
			}, {
				title: 'Абонемент на 8 занятий',
				price: 5875,
				count: 8,
			},
		],
		route: 'intellektika',
		// schedule: {
		// 	tue: '19:00 - 19:45',
		// 	thu: '19:00 - 19:45',
		// },
	}, {
		id: 20,
		title: 'Школа вокала',
		icon: mdiMicrophone,
		description: '',
		prices: [
			{
				title: 'Разовое занятие',
				price: 785,
				count: 1,
			}, {
				title: 'Абонемент на 4 занятия',
				price: 2826,
				count: 4,
			},
		],
		route: 'vocal',
	},
] as CatalogItem[];
