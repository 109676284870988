import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-05e41fb5"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!

  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      (_ctx.showCart)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["cart-icon", { animated: _ctx.animated }])
          }, [
            _createVNode(_component_v_icon, {
              value: _ctx.icons.cart
            }, null, 8, ["value"]),
            _createElementVNode("div", {
              class: "counter",
              textContent: _toDisplayString(_ctx.itemsCount)
            }, null, 8, _hoisted_1)
          ], 2))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}