
import { mdiMenu } from '@mdi/js';
import { defineComponent } from 'vue';
import { active } from './mainmenu';

const menu = [
	{
		path: '/',
		title: 'Главная',
	}, {
		title: 'Детский сад',
		name: 'detskiy-sad',
	}, {
		title: 'Английский язык',
		name: 'english',
	}, {
		title: 'Логопед',
		name: 'logoped',
	}, {
		title: 'Школа раннего развития',
		name: 'shkola-rannego-razvitiya',
	}, {
		title: 'Школа здоровья',
		name: 'shkola-zdorovia',
	}, {
		title: 'Подготовка к школе',
		name: 'podgotovka-k-shkole',
	}, {
		title: 'Каникулы',
		name: 'kanikuly-dlya-shkolnikov',
	}, {
		title: 'Контакты',
		name: 'contacts',
	}, {
		title: 'Юридическая информация',
		name: 'info',
		mobileOnly: true,
	},
];

const icons = {
	menu: mdiMenu,
};

export default defineComponent({
	setup() {
		function switchMenu(): void {
			active.value = !active.value;
		}

		return {
			active,
			menu,
			icons,
			switchMenu,
		};
	},
});
