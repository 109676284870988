import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a40ea610"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "button-container" }
const _hoisted_2 = { class: "fixed z-10 p-2 rounded-md bg-brand bg-opacity-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: "sign",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.sign && _ctx.sign(...args)))
      }, [
        _createVNode(_component_v_icon, {
          value: _ctx.icons.discount
        }, null, 8, ["value"]),
        _createTextVNode("Пробное занятие со скидкой 50%")
      ])
    ])
  ]))
}