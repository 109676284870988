import config from '@/config';

// eslint-disable-next-line import/prefer-default-export
export function reachGoal(goalName: string): void {
	if ('ym' in window) {
		(config.ym || []).forEach(counter => {
			ym(counter, 'reachGoal', goalName);
		});
	}
}

export function mailto(email: string): string {
	if (email) {
		return `mailto:${email}`;
	}

	return '';
}
