
import { defineComponent } from 'vue';
import {
	mdiEmail, mdiInstagram, mdiPhone, mdiVk, mdiYoutube, mdiMapMarker, mdiTelegram, mdiCalendar,
} from '@mdi/js';
import FullLogo from '@/components/FullLogo.vue';
import Banners from '@/components/Banners.vue';
import DzenLogo from '@/components/DzenLogo.vue';
import BigBanner from '@/components/BigBanner.vue';
import config from '@/config';
import { mailto } from '@/etc';

const icons = {
	vk: mdiVk,
	insta: mdiInstagram,
	youtube: mdiYoutube,
	telegram: mdiTelegram,
	phone: mdiPhone,
	email: mdiEmail,
	map: mdiMapMarker,
	calendar: mdiCalendar,
};

export default defineComponent({
	setup() {
		return {
			icons,
			config,
			mailto,
		};
	},
	components: {
		FullLogo,
		Banners,
		DzenLogo,
		BigBanner,
	},
});
