import { mdiAutoFix, mdiMushroom, mdiNintendoGameBoy } from '@mdi/js';
// import { PricePunctuation } from './types';

export default [
	{
		id: 14,
		title: 'Школа волшебников',
		description: 'Летние каникулы для школьников',
		icon: mdiAutoFix,
		prices: [
			// {
			//     title: '5 дней',
			//     price: 10000,
			// }, {
			//     title: '5 дней (При оплате за две недели до старта)',
			//     price: 9000,
			// }, {
			//     title: '5 дней (При оплате до 31 мая 2023)',
			//     price: 8000,
			// }, {
			//     title: '5 дней (При оплате до 01 апреля 2023)',
			//     price: 7000,
			// },
			// PricePunctuation.Separator,
			// {
			//     title: '10 дней',
			//     price: 18000,
			// }, {
			//     title: '10 дней (При оплате за две недели до старта)',
			//     price: 16200,
			// }, {
			//     title: '10 дней (При оплате до 31 мая 2023)',
			//     price: 14400,
			// }, {
			//     title: '10 дней (При оплате до 01 апреля 2023)',
			//     price: 12600,
			// },
			// PricePunctuation.Separator,
			// {
			//     title: '15 дней',
			//     price: 25000,
			// }, {
			//     title: '15 дней (При оплате за две недели до старта)',
			//     price: 22500,
			// }, {
			//     title: '15 дней (При оплате до 31 мая 2023)',
			//     price: 20000,
			// }, {
			//     title: '15 дней (При оплате до 01 апреля 2023)',
			//     price: 17500,
			// },
			// PricePunctuation.Separator,
			// {
			//     title: '20 дней',
			//     price: 30000,
			// }, {
			//     title: '20 дней (При оплате за две недели до старта)',
			//     price: 27000,
			// }, {
			//     title: '20 дней (При оплате до 31 мая 2023)',
			//     price: 24000,
			// }, {
			//     title: '20 дней (При оплате до 01 апреля 2023)',
			//     price: 21000,
			// },

		],
		route: 'kanikuly-dlya-shkolnikov',
	},
	// {
	//     id: 16,
	//     title: 'Дни рождения',
	//     prices: [],
	//     description: '',
	//     icon: mdiCakeVariant,
	//     route: 'birthdays',
	// },
	{
		id: 17,
		title: 'Тематические вечеринки',
		prices: [
			{
				title: 'Без сна',
				price: 1300,
			}, {
				title: 'Со сном',
				price: 1800,
			},
		],
		description: '',
		icon: mdiMushroom,
		route: 'vecherinki',
	},
	{
		id: 22,
		title: 'Игровая комната',
		prices: [
			{
				title: 'Будни, 09:00 - 10:00 (1 час)',
				price: 200,
			}, {
				title: 'Будни, 10:00 - 12:00 (1 час)',
				price: 300,
			}, {
				title: 'Будни, 18:00 - 20:00 (1 час)',
				price: 350,
			}, {
				title: 'Будни, 18:00 - 20:00 (30 мин.)',
				price: 200,
			}, {
				title: 'Выходные, 10:00 - 20:00 (1 час)',
				price: 350,
			}, {
				title: 'Выходные, 10:00 - 20:00 (30 мин)',
				price: 200,
			},
		],
		description: '',
		icon: mdiNintendoGameBoy,
		route: 'playroom',
	},
];
