import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_service_row = _resolveComponent("service-row")!
  const _component_age_tab = _resolveComponent("age-tab")!

  return (_openBlock(), _createBlock(_component_age_tab, {
    age: "6-7",
    image: "/img/ages/ages-6-7.jpg"
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
        return (_openBlock(), _createBlock(_component_service_row, {
          key: item.id,
          value: item,
          onClick: ($event: any) => (_ctx.click(item.id)),
          selected: _ctx.selectedId === item.id
        }, null, 8, ["value", "onClick", "selected"]))
      }), 128))
    ]),
    _: 1
  }))
}