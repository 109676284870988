
import { mdiArrowLeft, mdiArrowRight } from '@mdi/js';
import {
	computed, defineComponent, onBeforeUnmount, onMounted, onUnmounted, ref, watch,
} from 'vue';
import swipe, { Direction } from '@/swipe';
import Tab1 from './ServicesTabs/Tab1.vue';
import Tab2 from './ServicesTabs/Tab2.vue';
import Tab3 from './ServicesTabs/Tab3.vue';
import Tab4 from './ServicesTabs/Tab4.vue';

function getItemsCount(): number {
	/*
	'sm': '640px',
	// => @media (min-width: 640px) { ... }

	'md': '768px',
	// => @media (min-width: 768px) { ... }

	'lg': '1024px',
	// => @media (min-width: 1024px) { ... }

	'xl': '1280px',
	// => @media (min-width: 1280px) { ... }

	'2xl': '1536px',
	// => @media (min-width: 1536px) { ... }
	*/

	const width = document.body.offsetWidth;
	if (width < 640) {
		return 1;
	}

	if (width < 768) {
		return 2;
	}

	if (width < 1024) {
		return 3;
	}

	return 4;
}

const icons = {
	left: mdiArrowLeft,
	right: mdiArrowRight,
};

export default defineComponent({
	setup() {
		const tabs = [
			'tab1',
			'tab2',
			'tab3',
			'tab4',
		];

		const tabsEl = ref(null as HTMLDivElement|null);
		const selectedElement = ref(0);
		const screenIndex = ref(0);
		const totalCount = tabs.length;
		const visibleCount = ref(getItemsCount());
		const maxScreenIndex = computed(() => totalCount - visibleCount.value);
		const isArrowsVisible = ref(false);

		const observer = new IntersectionObserver(entries => {
			const entry = entries[0];
			isArrowsVisible.value = entry.isIntersecting;
		}, {
			rootMargin: '0px',
			threshold: 0.4,
		});

		function selectElement(index: number) {
			selectedElement.value = Math.min(Math.max(index, 0), totalCount - 1);
		}

		function openScreen(index: number) {
			screenIndex.value = Math.min(Math.max(index, 0), maxScreenIndex.value);
			if (visibleCount.value === 1) {
				selectedElement.value = screenIndex.value;
			}
		}

		function moveLeft() {
			openScreen(screenIndex.value - 1);
		}

		function moveRight() {
			openScreen(screenIndex.value + 1);
		}

		const onWindowResize = () => {
			visibleCount.value = getItemsCount();
			screenIndex.value = Math.min(Math.max(screenIndex.value, 0), maxScreenIndex.value);

			if (visibleCount.value === 1) {
				selectedElement.value = screenIndex.value;
			}
		};

		const { touchstart, touchmove, touchend } = swipe(direction => {
			switch (direction) {
				case Direction.Left:
					moveLeft();
					break;
				case Direction.Right:
					moveRight();
					break;
			}
		});

		watch(selectedElement, newIndex => {
			if (visibleCount.value > 3) {
				return;
			}

			if (visibleCount.value === 3) {
				const newScreenIndex = newIndex - 1;
				openScreen(newScreenIndex);

				return;
			}

			if (newIndex < screenIndex.value) {
				screenIndex.value = newIndex;
			} else if (newIndex > screenIndex.value + visibleCount.value - 1) {
				screenIndex.value += newIndex - (screenIndex.value + visibleCount.value - 1);
			}
		});

		const left = computed(() => {
			const padIndex = screenIndex.value;

			const relativeSize = (padIndex * 100) / visibleCount.value;

			return `-${relativeSize}%`;
		});

		onMounted(() => {
			window.addEventListener('resize', onWindowResize);

			if (tabsEl.value) {
				observer.observe(tabsEl.value);
			}
		});

		onUnmounted(() => {
			window.removeEventListener('resize', onWindowResize);
		});

		onBeforeUnmount(() => {
			if (tabsEl.value) {
				observer.unobserve(tabsEl.value);
			}
		});

		return {
			left,
			screenIndex,
			selectedElement,
			selectElement,
			tabs,
			icons,
			moveLeft,
			moveRight,
			totalCount,
			maxScreenIndex,
			touchstart,
			touchmove,
			touchend,
			tabsEl,
			isArrowsVisible,
		};
	},
	components: {
		Tab1,
		Tab2,
		Tab3,
		Tab4,
	},
});
