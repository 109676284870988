import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-09d8f9de"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bg-gradient-to-b from-blue-100 to-white pt-6" }
const _hoisted_2 = { class: "flex items-center justify-center mb-3" }
const _hoisted_3 = ["href"]
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "videos" }
const _hoisted_6 = { class: "video" }
const _hoisted_7 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, [
      _createTextVNode("Мы на "),
      _createElementVNode("a", {
        href: _ctx.config.youtube,
        target: "_blank"
      }, "YouTube", 8, _hoisted_3),
      _createElementVNode("a", {
        class: "icon",
        href: _ctx.config.youtube,
        target: "_blank"
      }, [
        _createVNode(_component_v_icon, {
          value: _ctx.icons.youtube
        }, null, 8, ["value"])
      ], 8, _hoisted_4)
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.videos, (video) => {
        return (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("iframe", {
            src: video,
            frameborder: "0",
            allowfullscreen: "",
            allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          }, null, 8, _hoisted_7)
        ]))
      }), 256))
    ])
  ]))
}