
import { defineComponent } from 'vue';
import MainMenu from '@/components/MainMenu.vue';
import VTitle from '@/components/Title.vue';
import AgeTabs from '@/components/AgeTabs.vue';
import BigBanner from '@/components/BigBanner.vue';
import ServicesList from '@/components/ServicesList.vue';
import FunServices from '@/components/FunServices.vue';
import VNumbers from '@/components/Numbers.vue';
import YouTube from '@/components/YouTube.vue';
import Conception from '@/components/conception/Conception.vue';
// import Insta from '@/components/Insta.vue';
import VMap from '@/components/Map.vue';
import VirtualTour from '@/components/VirtualTour.vue';
import SignButton from '@/components/SignButton.vue';

export default defineComponent({
	components: {
		MainMenu,
		VTitle,
		AgeTabs,
		BigBanner,
		ServicesList,
		FunServices,
		VNumbers,
		VMap,
		YouTube,
		Conception,
		// Insta,
		VirtualTour,
		SignButton,
	},
});
