import { RouteLocationNormalized } from 'vue-router';

function getHeaderHeight(): number {
	const header = document.getElementsByClassName('header-container')[0];
	return header ? header.scrollHeight : 0;
}

export default function scrollBehavior(to: RouteLocationNormalized, from: RouteLocationNormalized): void {
	const toLevel = to.path.split('/').length - 1;
	const fromLevel = from.path.split('/').length - 1;

	// Не сбрасываем скролл внутри страниц при переключении групп
	if (toLevel === 3 && fromLevel === 3) {
		return;
	}

	// Если мы со страницы услуги перешли на страницу группы этой услуги, то сбрасывать скролл не нужно
	if (fromLevel === 2 && toLevel === 3 && to.path.startsWith(from.path)) {
		return;
	}

	// Если переход осуществляется со страницы группы на страницу этой же услуги, но без группы, то сбрасывать скролл не нужно
	if (fromLevel === 3 && toLevel === 2 && from.path.startsWith(to.path)) {
		return;
	}

	if (fromLevel === 1 && toLevel > 1) {
		window.scrollTo({ top: 0 });
		return;
	}

	const headerHeight = getHeaderHeight();

	// Если пролистали совсем чуть-чуть, то скидывать скролл нет смысла
	if (window.scrollY < headerHeight) {
		return;
	}

	window.scrollTo({ top: headerHeight });
}
