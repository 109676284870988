
import { computed, defineComponent } from 'vue';
import VFooter from '@/components/Footer.vue';
import Cart from '@/components/cart/Cart.vue';
import cssSupport from '@/css-support';
import { active as mainMenuActive } from '@/components/mainmenu';

export default defineComponent({
	setup() {
		const classes = computed(() => ({ 'no-backdrop': !cssSupport.backdrop }));

		return {
			classes,
			mainMenuActive,
		};
	},
	components: {
		VFooter,
		Cart,
	},
});
