
import { mdiCartMinus } from '@mdi/js';
import { computed, defineComponent, ref } from 'vue';
import { ItemId } from '@/catalog/types';
import {
	cart, removeFromCart, isCartExpanded, closeCart, switchCart,
} from './cart';
import CartIcon from './CartIcon.vue';
import CartWindow from './CartWindow.vue';
import SentMessage from './SentMessage.vue';

const icons = {
	remove: mdiCartMinus,
};

export default defineComponent({
	setup() {
		const items = computed(() => cart.items);
		const wasSent = ref(false);

		function sent(): void {
			closeCart();
			wasSent.value = true;
		}

		function removeFromCartClick(itemId: ItemId) {
			removeFromCart(itemId);

			if (items.value.length === 0) {
				closeCart();
			}
		}

		return {
			icons,
			items,
			isCartExpanded,
			wasSent,
			switchCart,
			closeCart,
			sent,
			removeFromCart: removeFromCartClick,
		};
	},
	components: {
		CartIcon,
		CartWindow,
		SentMessage,
	},
});
