
import { mdiCartVariant, mdiClose, mdiNotebookMinus } from '@mdi/js';
import { computed, defineComponent } from 'vue';
import { CatalogItem, ItemId } from '@/catalog/types';
import { cart, removeFromCart } from './cart';
import CartForm from './CartForm.vue';
import VWindow from '../Window.vue';

const icons = {
	remove: mdiNotebookMinus,
	close: mdiClose,
	cart: mdiCartVariant,
};

function fullTitle(item: CatalogItem): string {
	if (item.age) {
		return `${item.title} (${item.age})`;
	}

	return item.title;
}

export default defineComponent({
	emits: ['close', 'sent'],

	setup(_props, ctx) {
		const items = computed(() => cart.items);

		function close(): void {
			ctx.emit('close');
		}

		function sent(): void {
			ctx.emit('sent');
			close();
		}

		function removeFromCartClick(itemId: ItemId) {
			removeFromCart(itemId);

			if (items.value.length === 0) {
				close();
			}
		}

		return {
			items,
			close,
			sent,
			removeFromCart: removeFromCartClick,
			icons,
			fullTitle,
		};
	},

	components: {
		CartForm,
		VWindow,
	},
});
