
import { computed, defineComponent } from 'vue';

export default defineComponent({
	props: {
		title: String,
		image: {
			type: String,
			required: true,
		},
		route: String,
	},
	setup(props) {
		const backgroundImage = computed(() => `url(${props.image})`);
		const url = computed(() => ({ name: props.route }));
		return {
			backgroundImage,
			url,
		};
	},
});
