
import { defineComponent } from 'vue';
import { mdiSale } from '@mdi/js';
import { addToCart, showCart } from '@/components/cart/cart';

const icons = {
	discount: mdiSale,
};

export default defineComponent({
	props: {
		value: {
			type: Number,
			required: true,
		},
	},
	setup(props) {
		function sign(): void {
			addToCart(props.value);
			showCart();
		}

		return {
			sign,
			icons,
		};
	},
});
