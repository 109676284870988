
import { computed, defineComponent } from 'vue';
import {
	mdiInformationVariant, mdiCartPlus, mdiCartMinus, mdiSale,
} from '@mdi/js';
import {
	CatalogItem, PriceItem, Price, isPrice,
} from '@/catalog/types';
import formatPrice from '@/format/price';
import {
	addToCart, isInCart, removeFromCart, showCart,
} from '../cart/cart';

const icons = {
	description: mdiInformationVariant,
	add: mdiCartPlus,
	remove: mdiCartMinus,
	discount: mdiSale,
};

/**
 * Фильтрует тарифы, если у тарифа есть условие на поялвение
 * Так некоторые тарифы появляются, только если выбрана определённая услуга
 *
 * @param price Price
 *
 * @returns boolean
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function priceFilter(_price: PriceItem): boolean {
	return true; // вероятно, нет смысла фильтровать тарифы для вывода самой низкой цены

	// if (!price.condition) {
	// 	return true;
	// }

	// return price.condition.some(isInCart);
}

/**
 * Вычисляет цену одного занятия, если тариф имеет ограничение по количеству посещений
 * Если ограничения нет, то возвращает оригинальную цену
 */
function absPrice(price: PriceItem): number {
	if (!isPrice(price)) {
		return NaN;
	}

	return price.price / (price.count || 1);
}

/**
 * Определяет, есть ли среди тарифов тарифы со скидкой на основе уже выбранных услуг
 */
function isDiscountActive(prices: PriceItem[]): boolean {
	return prices
		.filter(isPrice)
		.filter(price => !!price.condition) // тарифы со скидкой могу быть только с появлением по условию
		.some(priceItem => {
			const price = priceItem as Price;
			return price.condition && price.condition?.some(isInCart);
		});
}

export default defineComponent({
	props: {
		value: Object,
		selected: Boolean,
	},
	setup(props) {
		const value = props.value as CatalogItem;

		function addToCartClick() {
			addToCart(value.id);
			showCart();
		}

		function removeFromCartClick() {
			removeFromCart(value.id);
		}

		const isItemInCart = computed(() => isInCart(value.id));

		const minPrice = computed(() => {
			const min = Math.min(...value.prices
				.filter(priceFilter)
				.map(absPrice)
				.map(Math.ceil));

			if (!Number.isFinite(min)) {
				return '';
			}

			return formatPrice(min);
		});

		const hasDiscount = computed(() => isDiscountActive(value.prices));

		return {
			icons,
			addToCart: addToCartClick,
			removeFromCart: removeFromCartClick,
			isItemInCart,
			minPrice,
			hasDiscount,
		};
	},
});
