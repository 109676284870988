import { RouteRecordRaw, RouteComponent } from 'vue-router';

export default function getRoutes(homeComponent: RouteComponent | null): RouteRecordRaw[] {
	const inner: RouteRecordRaw[] = [
		{
			path: '/programs',
			redirect: {
				name: 'detskiy-sad-group-2',
			},
			children: [
				{
					path: 'english',
					name: 'english',
					component: () => import(
						/* webpackChunkName: "english" */ '@/views/services/english/English.vue'
					),
					redirect: {
						name: 'english-group-1',
					},
					children: [
						{
							path: 'group-0',
							name: 'english-group-0',
							component: () => import(
								/* webpackChunkName: "english" */ '@/views/services/english/Group0.vue'
							),
							meta: {
								title: 'Английский язык, ясельная группа',
							},
						},
						{
							path: 'group-1',
							name: 'english-group-1',
							component: () => import(
								/* webpackChunkName: "english" */ '@/views/services/english/Group1.vue'
							),
							meta: {
								title: 'Английский язык, I группа',
							},
						},
						{
							path: 'group-2',
							name: 'english-group-2',
							component: () => import(
								/* webpackChunkName: "english" */ '@/views/services/english/Group2.vue'
							),
							meta: {
								title: 'Английский язык, II группа',
							},
						},
						{
							path: 'group-3',
							name: 'english-group-3',
							component: () => import(
								/* webpackChunkName: "english" */ '@/views/services/english/Group3.vue'
							),
							meta: {
								title: 'Английский язык, III группа',
							},
						},
						{
							path: 'group-4',
							name: 'english-group-4',
							component: () => import(
								/* webpackChunkName: "english" */ '@/views/services/english/Group4.vue'
							),
							meta: {
								title: 'Английский язык, IV группа',
							},
						},
					],
				},
				{
					path: 'logoped',
					name: 'logoped',
					component: () => import(
						/* webpackChunkName: "logoped" */ '@/views/services/logoped/Logoped.vue'
					),
					meta: {
						title: 'Логопед',
					},
				},
				{
					path: 'detskiy-sad',
					name: 'detskiy-sad',
					redirect: {
						name: 'yasli',
					},
					children: [
						{
							path: 'yasli',
							name: 'yasli',
							component: () => import(
								/* webpackChunkName: "detskiy-sad" */ '@/views/services/detskiy-sad/Group1.vue'
							),
							meta: {
								title: 'Ясли',
							},
						},
						{
							path: 'group-2',
							name: 'detskiy-sad-group-2',
							component: () => import(
								/* webpackChunkName: "detskiy-sad" */ '@/views/services/detskiy-sad/Group2.vue'
							),
							meta: {
								title: 'Детский сад',
							},
						},
					],
				},
				{
					path: 'shkola-rannego-razvitiya',
					name: 'shkola-rannego-razvitiya',
					component: () => import(/* webpackChunkName: "shrr" */ '@/views/services/shrr/Shrr.vue'),
					meta: {
						title: 'Школа раннего развития',
					},
				},
				{
					path: 'podgotovka-k-shkole',
					name: 'podgotovka-k-shkole',
					component: () => import(
						/* webpackChunkName: "pdkshk" */ '@/views/services/pdkshk/Pdkshk.vue'
					),
					redirect: {
						name: 'gkp',
					},
					children: [
						{
							path: 'gkp',
							name: 'gkp',
							component: () => import(
								/* webpackChunkName: "pdkshk" */ '@/views/services/pdkshk/Gkp.vue'
							),
							meta: {
								title: 'Группа кратковременного пребывания / воскресная школа',
							},
						},
						{
							path: 'intellektika',
							name: 'intellektika',
							component: () => import(
								/* webpackChunkName: "pdkshk" */ '@/views/services/pdkshk/Intellektika.vue'
							),
							meta: {
								title: 'Интеллектика',
							},
						},
					],
					meta: {
						title: 'Подготовка к школе',
					},
				},
				{
					path: 'shkola-zdorovia',
					name: 'shkola-zdorovia',
					component: () => import(/* webpackChunkName: "shzd" */ '@/views/services/shzd/Shzd.vue'),
					redirect: {
						name: 'shkola-zdorovia-group-1',
					},
					children: [
						{
							path: 'group-1',
							name: 'shkola-zdorovia-group-1',
							component: () => import(
								/* webpackChunkName: "shzd" */ '@/views/services/shzd/Group1.vue'
							),
							meta: {
								title: 'Школа здоровья, группа I',
							},
						},
						{
							path: 'group-2',
							name: 'shkola-zdorovia-group-2',
							component: () => import(
								/* webpackChunkName: "shzd" */ '@/views/services/shzd/Group2.vue'
							),
							meta: {
								title: 'Школа здоровья, группа II',
							},
						},
					],
				},
				{
					path: 'music-studio',
					component: () => import(
						/* webpackChunkName: "music_studio" */ '@/views/services/music-studio/MusicStudio.vue'
					),
					children: [
						{
							path: '',
							name: 'music-studio',
							component: () => import(
								/* webpackChunkName: "music_studio" */ '@/views/services/music-studio/MusicStudioGroup.vue'
							),
							meta: {
								title: 'Музыкально-театральная студия',
							},
						},
						{
							path: 'vocal',
							name: 'vocal',
							component: () => import(
								/* webpackChunkName: "music_studio" */ '@/views/services/music-studio/Vocal.vue'
							),
							meta: {
								title: 'Школа вокала',
							},
						},
					],
				},
				{
					path: 'prodlenka',
					name: 'prodlenka',
					component: () => import(
						/* webpackChunkName: "prodlenka" */ '@/views/services/prodlenka/Prodlenka.vue'
					),
					meta: {
						title: 'Продлёнка',
					},
				},
			],
			component: () => import(/* webpackChunkName: "programs" */ '@/views/Programs.vue'),
		},
		{
			path: '/fun',
			redirect: {
				name: 'vecherinki',
			},
			children: [
				{
					path: 'kanikuly-dlya-shkolnikov',
					name: 'kanikuly-dlya-shkolnikov',
					component: () => import(
						/* webpackChunkName: "kanikuli" */ '@/views/services/kanikuli/Kanikuli.vue'
					),
					meta: {
						title: 'Каникулы для школьников',
					},
				},
				// {
				//     path: 'birthdays',
				//     name: 'birthdays',
				//     component: () => import(
				//         [> webpackChunkName: "programs" <] '@/views/services/birthdays/Birthdays.vue'
				//     ),
				//     meta: {
				//         title: 'Дни рождения',
				//     },
				// },
				{
					path: 'vecherinki',
					name: 'vecherinki',
					component: () => import(
						/* webpackChunkName: "programs" */ '@/views/services/vecherinki/Vecherinki.vue'
					),
					meta: {
						title: 'Тематические вечеринки',
					},
				},
				{
					path: 'family-holidays',
					name: 'family-holidays',
					component: () => import(
						/* webpackChunkName: "family-holidays" */ '@/views/services/family-holidays/FamilyHolidays.vue'
					),
					meta: {
						title: 'Праздники для всей семьи',
					},
				},
				{
					path: 'playroom',
					name: 'playroom',
					component: () => import(
						/* webpackChunkName: "playroom" */ '@/views/services/playroom/Playroom.vue'
					),
					meta: {
						title: 'Игровая комната',
					},
				},
			],
			component: () => import(/* webpackChunkName: "programs" */ '@/views/Programs.vue'),
		},
		{
			path: '/contacts',
			name: 'contacts',
			component: () => import(/* webpackChunkName: "about" */ '@/views/contacts/Contacts.vue'),
			meta: {
				title: 'Контакты',
			},
		},
		{
			path: '/info',
			meta: {
				title: 'Юридическая информация',
			},
			component: () => import(/* webpackChunkName: "info" */ '@/views/info/Index.vue'),
			children: [
				{
					path: '',
					name: 'info',
					component: () => import(/* webpackChunkName: "info" */ '@/views/info/Info.vue'),
				},
				{
					path: 'codex',
					name: 'info-codex',
					component: () => import(/* webpackChunkName "info" */ '@/views/info/Codex.vue'),
				},
				{
					path: 'license',
					name: 'info-license',
					component: () => import(/* webpackChunkName "info" */ '@/views/info/License.vue'),
				},
				{
					path: 'programs',
					name: 'info-programs',
					component: () => import(/* webpackChunkName "info" */ '@/views/info/Programs.vue'),
				},
				{
					path: 'mto',
					name: 'info-mto',
					component: () => import(/* webpackChunkName "info" */ '@/views/info/MTO.vue'),
				},
			],
		},
	];

	if (homeComponent) {
		const root = {
			path: '/',
			component: homeComponent,
			meta: {
				hideMenu: true,
			},
		};

		return [root, ...inner];
	}

	return inner;
}
